import GoogleMapReact from 'google-map-react'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import styled from 'styled-components/macro'

import { VISIBLE_CLUSTER_ZOOM_LIMIT } from '../../constants/map'
import { fetchFilterCounts } from '../../redux/filterSlice'
import { setFromSettings, updatePosition } from '../../redux/locationSlice'
import { clearInitialView, setGoogle } from '../../redux/mapSlice'
import { fetchLocations } from '../../redux/viewChange'
import { updateLastMapView } from '../../redux/viewportSlice'
import throttle from '../../utils/throttle'
import { useAppHistory } from '../../utils/useAppHistory'
import AddLocationButton from '../ui/AddLocationButton'
import LoadingIndicator from '../ui/LoadingIndicator'
import CloseStreetView from './CloseStreetView'
import Cluster from './Cluster'
import { ConnectGeolocation, isGeolocationOpen } from './ConnectGeolocation'
import GeolocationDot from './GeolocationDot'
import Location from './Location'
import PanoramaHandler from './PanoramaHandler'
import {
  AddLocationCentralUnmovablePin,
  DraggableMapPin,
  EditLocationCentralUnmovablePin,
} from './Pins'
import Place from './Place'
import TrackLocationButton from './TrackLocationButton'

const MIN_ZOOM = 1

const BottomLeftLoadingIndicator = styled(LoadingIndicator)`
  position: absolute;
  left: 10px;
  bottom: 10px;
`

const ZoomButton = styled.button`
  position: absolute;
  left: 10px;
  width: 40px;
  height: 40px;
  background-color: white;
  ${({ isDesktop }) =>
    isDesktop &&
    `
    &:hover {
      background-color: #f0f0f0;
    }
  `}
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  z-index: 1;
`

const ZoomInButton = styled(ZoomButton)`
  top: calc(50% - 45px);
`

const ZoomOutButton = styled(ZoomButton)`
  top: calc(50% + 5px);
`

const EARTH_RADIUS = 6378137 // meters
const EARTH_CIRCUMFERENCE = 2 * Math.PI * EARTH_RADIUS // meters
//AI generated by giving it https://github.com/falling-fruit/falling-fruit-api/blob/main/helpers.js
const clusterBounds = ({ lat, lng, zoom }) => {
  // Convert WGS84 to Web Mercator
  const mercator = {
    x: (lng / 360) * EARTH_CIRCUMFERENCE,
    y: Math.log(Math.tan((lat + 90) * (Math.PI / 360))) * EARTH_RADIUS,
  }

  // Convert Web Mercator to grid cell indices
  const cell_size = EARTH_CIRCUMFERENCE / 2 ** zoom
  const cell = {
    x: Math.floor((mercator.x + EARTH_CIRCUMFERENCE / 2) / cell_size),
    y: Math.floor((mercator.y + EARTH_CIRCUMFERENCE / 2) / cell_size),
  }

  // Convert grid cell indices to Web Mercator bounds
  // (cell.x, cell.y) is the bottom-left (south-west) corner of the cell
  const bounds = {
    south: cell.y * cell_size - EARTH_CIRCUMFERENCE / 2,
    west: cell.x * cell_size - EARTH_CIRCUMFERENCE / 2,
    north: (cell.y + 1) * cell_size - EARTH_CIRCUMFERENCE / 2,
    east: (cell.x + 1) * cell_size - EARTH_CIRCUMFERENCE / 2,
  }

  // Convert Web Mercator bounds to WGS84
  return {
    south:
      90 -
      (Math.atan2(1, Math.exp(bounds.south / EARTH_RADIUS)) * 360) / Math.PI,
    west: bounds.west * (360 / EARTH_CIRCUMFERENCE),
    north:
      90 -
      (Math.atan2(1, Math.exp(bounds.north / EARTH_RADIUS)) * 360) / Math.PI,
    east: bounds.east * (360 / EARTH_CIRCUMFERENCE),
  }
}
const makeHandleViewChange =
  (dispatch, googleMap, history) => (googleMapReactCallbackArg) => {
    const center = googleMap.getCenter()
    const newView = {
      center: { lat: center.lat(), lng: center.lng() },
      zoom: googleMap.getZoom(),
      bounds: googleMap.getBounds().toJSON(),
      width: googleMap.getDiv().offsetWidth,
      height: googleMap.getDiv().offsetHeight,
    }
    dispatch(updateLastMapView(newView))
    dispatch(fetchLocations())
    dispatch(fetchFilterCounts())
    if (googleMapReactCallbackArg) {
      history.changeView(newView)
    }
  }

/**
 * Calculate XYZ tile coordinates.
 *
 * @param {Object} coord - Google Maps world coordinates.
 * @param {number} zoom - Google Maps zoom level.
 * @returns {Object} XYZ tile coordinates. `y` is `null` if out of bounds.
 */
function getTileCoordinates(coord, zoom) {
  // Wrap x (longitude) at 180th meridian properly
  const tilesPerGlobe = 1 << zoom
  let x = coord.x % tilesPerGlobe
  if (x < 0) {
    x = tilesPerGlobe + x
  }
  let y = coord.y
  if (coord.y < 0 || coord.y >= tilesPerGlobe) {
    y = null
  }
  return { x, y, z: zoom }
}

const GoogleMapWrapper = ({ onUnmount, ...props }) => {
  useEffect(() => onUnmount, []) //eslint-disable-line

  return <GoogleMapReact {...props} />
}

const MapPage = ({ isDesktop }) => {
  const { t, i18n } = useTranslation()
  const history = useAppHistory()
  const dispatch = useDispatch()
  const handleViewChangeRef = useRef(() => void 0)

  const [draggedPosition, setDraggedPosition] = useState(null)

  const {
    initialView,
    locations,
    clusters,
    isLoading: mapIsLoading,
    googleMap,
    getGoogleMaps,
  } = useSelector((state) => state.map)

  const currentZoom = googleMap?.getZoom()

  const place = useSelector((state) => state.place.selectedPlace)

  const { geolocation, geolocationState } = useSelector(
    (state) => state.geolocation,
  )
  const { pathname } = useLocation()
  const {
    locationId,
    position,
    isBeingEdited: isEditingLocation,
    location: selectedLocation,
    isLoading: locationIsLoading,
    streetViewOpen: showStreetView,
    isBeingInitializedMobile,
  } = useSelector((state) => state.location)
  const {
    mapType,
    mapLayers: layerTypes,
    showLabels: settingsShowLabels,
    showBusinesses,
  } = useSelector((state) => state.settings)

  const { typesAccess } = useSelector((state) => state.type)

  const ready = !typesAccess.isEmpty && !!googleMap
  useEffect(() => {
    if (ready) {
      handleViewChangeRef.current(false)
    }
  }, [ready])

  const allLocations =
    clusters.length !== 0
      ? []
      : selectedLocation
        ? [...locations, selectedLocation].filter(
            (loc, index, self) =>
              index === self.findIndex((t) => t.id === loc.id),
          )
        : locations

  const isAddingLocation = locationId === 'new' || isBeingInitializedMobile
  const isViewingLocation =
    locationId !== null && !isEditingLocation && !isAddingLocation
  const showLabels = settingsShowLabels || isAddingLocation || isEditingLocation

  useEffect(() => {
    setDraggedPosition(isDesktop ? position : null)
  }, [position, isDesktop])

  const apiIsLoaded = (map, maps) => {
    /*
     * Install the handler as we now have all variables
     */
    handleViewChangeRef.current = throttle(
      makeHandleViewChange(dispatch, map, history),
      1000,
    )
    /*
     * Something breaks when storing maps in redux so pass a reference to it
     */
    dispatch(setGoogle({ googleMap: map, getGoogleMaps: () => maps }))
  }

  const handleClusterClick = (cluster) => {
    if (cluster.count === 1) {
      googleMap?.panTo({
        lat: cluster.lat,
        lng: cluster.lng,
      })
      googleMap?.setZoom(VISIBLE_CLUSTER_ZOOM_LIMIT + 1)
    } else {
      const bounds = clusterBounds({
        lat: cluster.lat,
        lng: cluster.lng,
        zoom: currentZoom + 1,
      })
      googleMap?.fitBounds(bounds)
    }
  }

  const handleLocationClick = (location) => {
    if (isDesktop && pathname.includes('/settings')) {
      dispatch(setFromSettings(true))
    }
    history.push(`/locations/${location.id}`)
  }

  const handleNonspecificClick = ({ event }) => {
    event.stopPropagation()
    if (isViewingLocation) {
      history.push('/map')
    }
  }

  const handleAddLocationClick = () => {
    if (currentZoom >= VISIBLE_CLUSTER_ZOOM_LIMIT) {
      history.push('/locations/init')
    } else {
      toast.info(t('menu.zoom_in_to_add_location'))
    }
  }

  const zoomIn = () => {
    googleMap?.setZoom(currentZoom + 1)
  }
  const zoomOut = () => {
    googleMap?.setZoom(currentZoom - 1)
  }
  return (
    <div
      style={
        isDesktop
          ? { width: '100%', height: '100%', position: 'relative' }
          : {
              width: '100%',
              position: 'absolute',
              top: '48px',
              bottom: '50px',
              left: 0,
              right: 0,
            }
      }
    >
      {(mapIsLoading || locationIsLoading) && <BottomLeftLoadingIndicator />}
      {isAddingLocation && !isDesktop && <AddLocationCentralUnmovablePin />}
      {!isAddingLocation && !isEditingLocation && !isDesktop && (
        <AddLocationButton onClick={handleAddLocationClick} />
      )}
      {isEditingLocation && !isDesktop && <EditLocationCentralUnmovablePin />}
      {!isDesktop && <TrackLocationButton isIcon />}

      <ZoomInButton
        onClick={zoomIn}
        disabled={
          !currentZoom || currentZoom >= (mapType === 'roadmap' ? 22 : 21)
        }
        isDesktop={isDesktop}
      >
        +
      </ZoomInButton>
      <ZoomOutButton
        onClick={zoomOut}
        disabled={!currentZoom || currentZoom <= MIN_ZOOM}
        isDesktop={isDesktop}
      >
        -
      </ZoomOutButton>

      {isGeolocationOpen(geolocationState) && <ConnectGeolocation />}

      {googleMap && <PanoramaHandler />}
      {showStreetView && <CloseStreetView />}
      {initialView && (
        <GoogleMapWrapper
          onClick={handleNonspecificClick}
          bootstrapURLKeys={{
            apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
            version: 'beta',
            libraries: ['places'],
            language: i18n.language,
          }}
          options={(googleMaps) => ({
            mapTypeId: mapType,
            disableDefaultUI: true,
            rotateControlOptions: {
              position: googleMaps.ControlPosition.INLINE_START_BLOCK_END,
            },
            minZoom: MIN_ZOOM,
            // Toggle all basemap icons
            // https://developers.google.com/maps/documentation/javascript/style-reference
            styles: [
              {
                featureType: 'poi',
                elementType: 'labels.icon',
                stylers: [{ visibility: showBusinesses ? 'on' : 'off' }],
              },
              {
                featureType: 'landscape',
                elementType: 'labels.icon',
                stylers: [{ visibility: showBusinesses ? 'on' : 'off' }],
              },
            ],
          })}
          layerTypes={layerTypes}
          defaultCenter={initialView.center}
          defaultZoom={initialView.zoom}
          onChange={handleViewChangeRef.current}
          onGoogleApiLoaded={({ map, maps }) => {
            map.mapTypes.set(
              'osm-standard',
              new maps.ImageMapType({
                getTileUrl: (coord, zoom) => {
                  const { x, y, z } = getTileCoordinates(coord, zoom)
                  if (y !== null) {
                    return `https://tile.openstreetmap.org/${z}/${x}/${y}.png`
                  }
                },
                tileSize: new maps.Size(256, 256),
                maxZoom: 19,
              }),
            )
            map.mapTypes.set(
              'osm-toner-lite',
              new maps.ImageMapType({
                getTileUrl: (coord, zoom) => {
                  const { x, y, z } = getTileCoordinates(coord, zoom)
                  if (y !== null) {
                    return `https://tiles.stadiamaps.com/tiles/stamen_toner-lite/${z}/${x}/${y}.png`
                  }
                },
                tileSize: new maps.Size(256, 256),
                maxZoom: 20,
              }),
            )
            apiIsLoaded(map, maps)
          }}
          yesIWantToUseGoogleMapApiInternals
          onUnmount={() => {
            dispatch(clearInitialView())
          }}
        >
          {geolocation && !geolocation.loading && !geolocation.error && (
            <GeolocationDot
              lat={geolocation.latitude}
              lng={geolocation.longitude}
            />
          )}
          {place &&
            place.location &&
            place.view &&
            place.view.zoom >= VISIBLE_CLUSTER_ZOOM_LIMIT &&
            currentZoom >= VISIBLE_CLUSTER_ZOOM_LIMIT && (
              <Place
                lat={place.location.lat}
                lng={place.location.lng}
                label={place.location.description}
              />
            )}
          {clusters.map((cluster) => (
            <Cluster
              key={JSON.stringify(cluster)}
              onClick={(event) => {
                handleClusterClick(cluster)
                event.stopPropagation()
              }}
              count={cluster.count}
              lat={cluster.lat}
              lng={cluster.lng}
            />
          ))}
          {allLocations.map((location) => (
            <Location
              key={location.id}
              onClick={
                isEditingLocation || isAddingLocation
                  ? null
                  : (event) => {
                      handleLocationClick(location)
                      event.stopPropagation()
                    }
              }
              lat={location.lat}
              lng={location.lng}
              selected={location.id === locationId}
              editing={isEditingLocation && location.id === locationId}
              showLabel={showLabels}
              typeIds={location.type_ids}
            />
          ))}
          {(isEditingLocation || isAddingLocation) && draggedPosition && (
            <DraggableMapPin
              lat={draggedPosition.lat}
              lng={draggedPosition.lng}
              // confusingly it doesn't work from inside the component
              $geoService={getGoogleMaps && getGoogleMaps().Geocoder}
              onChange={setDraggedPosition}
              onDragEnd={(newPosition) => {
                dispatch(updatePosition(newPosition))
              }}
            />
          )}
        </GoogleMapWrapper>
      )}
    </div>
  )
}

export default MapPage
